


































import { mapGetters } from "vuex";
import Gravatar from "vue-gravatar";
import LoginRequired from "@/components/LoginRequired.vue";

export default {
  name: "Profile",
  components: {
    LoginRequired,
    Gravatar,
  },
  computed: {
    ...mapGetters("accounts", [
      "isInitialized",
      "isUserConnected",
      "getActiveAccount",
      "getActiveBalanceEth",
    ]),
  },
};
