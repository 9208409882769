












export default {
  name: "LoginRequired",
  props: {
    initialized: Boolean,
    connected: Boolean,
  },
};
